import * as React from "react";
import type { HeadFC } from "gatsby";
import { graphql } from "gatsby";
import DocLayout from "../components/Layout";

interface data {
  site: { siteMetadata: { title: string } };
  allMarkdownRemark: any;
}

const IndexPage = ({ location }: { location: any }) => {

  return (
    <DocLayout location={location}>
      Home page
    </DocLayout>
  );
};

export default IndexPage;

export const Head: HeadFC = ({ data }: any) => (
  <title>{data.site.siteMetadata.title}</title>
);

export const query = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark {
      edges {
        node {
          html
          tableOfContents
          headings {
            id
            depth
            value
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`;
